import React from "react"
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n/config";
import trackCustomEvent from "./util/trackCustomEvent";
import {AnimatePresence} from "framer-motion";


class Layout extends React.Component {

    componentDidMount() {
        const {pageContext} = this.props;
        const locale = pageContext?.locale || 'de';
        if (i18n.language !== locale) {
            i18n.changeLanguage(locale)
        }

        // Listen for calendly events and fire conversion event
        function isCalendlyEvent(e) {
            return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
        };

        window.addEventListener("message", function(e) {
            if(isCalendlyEvent(e)) {
                let interaction

                switch (e.data.event) {
                    case "calendly.profile_page_viewed":
                        interaction = 'profile_viewed'
                        break;
                    case "calendly.event_type_viewed":
                        interaction = 'event_viewed'
                        break;
                    case "calendly.date_and_time_selected":
                        interaction = 'date_time_selected'
                        break;
                    case "calendly.event_scheduled":
                        interaction = 'event_scheduled'
                        break;
                }

                if (interaction) {
                    trackCustomEvent({
                        eventName: "calendly",
                        params: {
                            interaction: interaction
                        }
                    })
                }
            }
        });
    }

    render() {
        return (
            <I18nextProvider i18n={i18n}>
                <AnimatePresence mode={"wait"} initial={false}>
            {this.props.children}
                </AnimatePresence>
        </I18nextProvider>)
    }
}

export default Layout;
