/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import {AnimatePresence} from 'framer-motion';

function isReferencePage(pathname) {
  return /\/projekte\/.+/.test(pathname);
}

const transitionDelay = 500

export const shouldUpdateScroll = ({
                                routerProps: { location },
                                getSavedScrollPosition,
                                prevRouterProps
                              }) => {
  if (
    location &&
    prevRouterProps &&
    isReferencePage(prevRouterProps.location.pathname) &&
    isReferencePage(location.pathname)
  ) {
    return false;
  }

  if (location.hash) {
    const element = document.getElementById(location.hash.replace('#', ''))
    element.classList.add('Q8TNqvORq3')
    console.log(location.hash.replace('#', ''))
    element.scrollIntoView()
  } else {
    if (location.action === 'PUSH') {
      window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
      const savedPosition = getSavedScrollPosition(location)
      window.setTimeout(
          () => window.scrollTo(...(savedPosition || [0, 0])),
          transitionDelay
      )
    }
  }
  return false
}


export const onRouteUpdate = ({ location }, pluginOptions = {}) => {
  if (typeof gtag !== `function`) {
    return null
  }

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    window.gtag('event', 'page_view', {
      page_title: location.title,
      page_location: location.href,
      page_path: pagePath
    })
    window.fbq('track', 'PageView');
  }

  setTimeout(sendPageView, 0)

  return null
}
